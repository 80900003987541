import React from "react";
import "./Hero.scss";

const Hero = () => {
  return (
    <section id="homepage-hero" className="d-flex w-100 h-100">
      <div className="h-auto w-100">
        <div className="article-container d-flex align-items-center justify-content-center flex-column">
          <h2 className="white-color font-gotham-bold font-size-56 title-container px-3 mb-4">
            Changelog
          </h2>
          <h4 className="text-center font-gotham-light font-size-24 gray-color p-container px-3 mb-4">
            For any questions regarding our products do not hesitate to contact
            us. We will reply to all emails in timely manner.
          </h4>
          <a
            className="crypto-btn-link-base font-gotham-book text-decoration-none blue-color"
            href="https://cryptodata.com/cryptodata-support"
          >
            Acces our Customer Support
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
