import React from 'react'
import "./TellUs.scss";
import {ReactComponent as Wispr} from "./Assets/wispr.svg";
import {ReactComponent as Email} from "./Assets/email.svg";



const TellUs = () => {
  return (
    <section id="homepage-tell-us" className="d-flex w-100 h-100 pt-5">
      <div className="tell-us-container m-auto h-auto w-100">
        <div className="article-container d-flex align-items-center justify-content-center flex-column">
          <h2 className="black-color text-center font-gotham-bold font-size-48 title-container px-3 mb-4">
            Tell us how <br/>
            we can help
          </h2>
          <h4 className="text-center font-gotham-light font-size-24 dark-gray-color p-container px-3 mb-4">
                Need more help? Save time by starting your support 
                request online and we’ll connect you to a specialist.
          </h4>
          
          <div className='mt-4 contact-container d-flex gap-4 align-items-center justify-content-center'>
            <a href="mailto:office@cryptodata.com" className='text-decoration-none email-contact font-gotham-book font-size-24 dark-gray-color'>
                <Email className="me-2"/>
                <span>Email us</span>
            </a>
            <div className='wispr-contact font-gotham-book font-size-24 dark-gray-color'>
                <Wispr className="me-2"/>
                <span>Impulse@wisp</span>
            </div>

          </div>

        </div>
      </div>
    </section>
  )
}

export default TellUs