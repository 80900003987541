import React, { useEffect, useState } from "react";
import "./ChangeLog.scss";
import { ReactComponent as ImpulseICO } from "./Assets/Impulse.svg";
import { ReactComponent as BasmICO } from "./Assets/BASM.svg";
import { ReactComponent as HidrICO } from "./Assets/Hidr.svg";
import { ReactComponent as WisprICO } from "./Assets/Wispr.svg";
import { ReactComponent as OSICO } from "./Assets/CryptoDATA-OS.svg";
import { ReactComponent as AppsICO } from "./Assets/Secured-apps.svg";
import { ReactComponent as ImpulseBlueICO } from "./Assets/Impulse-blue.svg";
import { ReactComponent as BasmBlueICO } from "./Assets/BASM-blue.svg";
import { ReactComponent as HidrBlueICO } from "./Assets/hidr-blue.svg";
import { ReactComponent as WisprBlueICO } from "./Assets/Wispr-blue.svg";
import { ReactComponent as OSBlueICO } from "./Assets/CryptoDATA-OS-blue.svg";
import { ReactComponent as AppsBlueICO } from "./Assets/Secured-apps-blue.svg";
import { ReactComponent as AiOutlinePlus } from "./Assets/plus.svg";

import { ReactComponent as Basm } from "./Assets/Basm-changelog.svg";
import { ReactComponent as Minter } from "./Assets/minter-changelog.svg";
import { ReactComponent as Impulse } from "./Assets/impulse-changelog.svg";
import { ReactComponent as Hidr } from "./Assets/hidr-changelog.svg";
import { ReactComponent as Pool } from "./Assets/pools-changelog.svg";
import { Accordion } from "react-bootstrap";
import axios from "axios";

const ChangeLog = () => {
  const [devices, setDevices] = useState({
    hidr: true,
    impulse: true,
    basm: true,
    wispr: true,
    os: true,
    apps: true,
  });

  const DEVICES = ["hidr", "impulse", "basm", "wispr", "os", "apps"]

  const [filters, setFilters] = useState(["hidr"]);

  const handleChange = (device) => {
    setDevices({ ...devices, [device]: !devices[device] });
  };

  const [visibile, setVisibile] = useState(3);
  const [changelogPools, setChangeLogPools] = useState([{}]);
  const [changelogImpulse, setChangeLogImpulse] = useState([{}]);
  const [changelogMinter, setChangeLogMinter] = useState([{}]);
  const [changelogHidr, setChangeLogHidr] = useState([{}]);
  const [changelogBasm, setChangeLogBasm] = useState([{}]);
  const [changelog, setChangeLog] = useState([{}]);

  const handleLoadMore = () => {
    setVisibile((prevState) => prevState + 2);
  };

  useEffect(() => {
    const fetchDataImpulse = async () => {
      try {
        const response = await axios.get(
          "https://visibility.cryptodata.com/updates/custom/cdt/impulse"
        );

        if (response.status === 200) {
          setChangeLogImpulse(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchDataPools = async () => {
      try {
        const response = await axios.get(
          "https://visibility.cryptodata.com/updates/custom/cdt/pools"
        );

        if (response.status === 200) {
          setChangeLogPools(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchDataMinter = async () => {
      try {
        const response = await axios.get(
          "https://visibility.cryptodata.com/updates/custom/cdt/minter"
        );

        if (response.status === 200) {
          setChangeLogMinter(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const fetchDataHidr = async () => {
      try {
        const response = await axios.get(
          "https://visibility.cryptodata.com/updates/custom/cdt/hidr"
        );

        if (response.status === 200) {
          setChangeLogHidr(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const fetchDataBasm = async () => {
      try {
        const response = await axios.get(
          "https://visibility.cryptodata.com/updates/custom/cdt/basm"
        );

        if (response.status === 200) {
          setChangeLogBasm(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataHidr();
    fetchDataMinter();
    fetchDataPools();
    fetchDataImpulse();
    fetchDataBasm();
  }, []);

  useEffect(() => {
    setChangeLog([
      ...changelogHidr,
      ...changelogImpulse,
      ...changelogBasm,
      ...changelogPools,
      ...changelogMinter,
    ]);

    setFilters([...DEVICES.map(el => {
      if(devices[el]) return el
      return null
    })])

   
    
  }, [
    changelogImpulse,
    changelogPools,
    changelogMinter,
    changelogHidr,
    changelogBasm,
    devices
  ]);

  const handleTitleClasses = (type) => {
    switch (type) {
      case "fix":
        return "change-log-fix";
      case "new":
        return "change-log-new";
      case "update":
        return "change-log-update-type";
      default:
        return "";
    }
  };
  const handleName = (device) => {
    switch (device) {
      case "minter":
        return "MinteR";
      case "impulse":
        return "IMPulse";
      case "hidr":
        return "HidR";
      case "basm":
        return "BASM";
      case "pools":
        return "Pools";
      default:
        return "";
    }
  };
  const handleChangeType = (type) => {
    switch (type) {
      case "automatic":
        return "automatic-update";
      case "manual":
        return "manual-update";
      default:
        return "manual-update";
    }
  };
  const handleUpdateType = (type) => {
    switch (type) {
      case "automatic":
        return "Automatic Update";
      case "manual":
        return "Manual Update";
      default:
        return "manual-update";
    }
  };

  const handleIconDevice = (device) => {
    switch (device) {
      case "minter":
        return <Minter className="me-3 p-02" />;
      case "impulse":
        return <Impulse className="me-3 p-02" />;
      case "basm":
        return <Basm className="me-3 p-02" />;
      case "pools":
        return <Pool className="me-3 p-02" />;
      case "hidr":
        return <Hidr className="me-3 p-03" />;
      default:
        return "";
    }
  };

  


  return (
    <section id="changelog" className="pt-5">
      <div className="article-container">
        <h2 className="user-select-none font-size-56 color-black font-gotham-bold text-center mb-5">
          Find updates
        </h2>

        <div className="device-select-container d-flex align-items-end justify-content-between ">
          <div
            className="ico-container d-flex align-items-center justify-content-center flex-column w-100"
            onClick={() => handleChange("hidr")}
          >
            <div className="h-100 individual-ico d-flex align-items-center justify-content-center">
              {devices.hidr ? <HidrBlueICO className="hidr-ico"/> : <HidrICO className="hidr-ico"/>}
            </div>

            <span
              className={`mt-auto font-gotham-light user-select-none ${
                devices.hidr ? "blue-color" : "black-color"
              }`}
            >
              HidR
            </span>
          </div>
          <div
            className="ico-container d-flex align-items-center justify-content-center flex-column w-100"
            onClick={() => handleChange("impulse")}
          >
            <div className="h-100 individual-ico d-flex align-items-center justify-content-center">
              {devices.impulse ? <ImpulseBlueICO className="impulse-ico"/> : <ImpulseICO className="impulse-ico"/>}
            </div>
            <span
              className={`mt-auto font-gotham-light user-select-none ${
                devices.impulse ? "blue-color" : "black-color"
              }`}
            >
              IMPulse
            </span>
          </div>
          <div
            className="ico-container d-flex align-items-center justify-content-center flex-column w-100"
            onClick={() => handleChange("basm")}
          >
            <div className="h-100 individual-ico d-flex align-items-center justify-content-center">
              {devices.basm ? <BasmBlueICO className="basm-ico"/> : <BasmICO className="basm-ico"/>}
            </div>
            <span
              className={`mt-auto font-gotham-light user-select-none ${
                devices.basm ? "blue-color" : "black-color"
              }`}
            >
              BASM
            </span>
          </div>
          <div
            className="ico-container d-flex align-items-center justify-content-center flex-column w-100"
            onClick={() => handleChange("wispr")}
          >
            <div className="h-100 individual-ico d-flex align-items-center justify-content-center">
              {devices.wispr ? <WisprBlueICO className="wispr-ico"/> : <WisprICO className="wispr-ico"/>}
            </div>
            <span
              className={`mt-auto font-gotham-light user-select-none ${
                devices.wispr ? "blue-color" : "black-color"
              }`}
            >
              Wispr
            </span>
          </div>
          <div
            className="ico-container d-flex align-items-center justify-content-center flex-column w-100"
            onClick={() => handleChange("os")}
          >
            <div className="h-100 individual-ico d-flex align-items-center justify-content-center">
              {devices.os ? <OSBlueICO className="os-ico"/> : <OSICO className="os-ico"/>}
            </div>
            <span
              className={`mt-auto font-gotham-light user-select-none ${devices.os ? "blue-color" : "black-color"}`}
            >
              OS
            </span>
          </div>
          <div
            className="ico-container d-flex align-items-center justify-content-center flex-column w-100"
            onClick={() => handleChange("apps")}
          >
            <div className="h-100 individual-ico d-flex align-items-center justify-content-center">
              {devices.apps ? <AppsBlueICO className="apps-ico"/> : <AppsICO className="apps-ico"/>}
            </div>
            <span
              className={`mt-auto font-gotham-light user-select-none ${
                devices.apps ? "blue-color" : "black-color"
              }`}
            >
              Apps
            </span>
          </div>
        </div>

        <Accordion className="w-100">
          {changelog?.filter(el => filters.includes(el?.device)).length > 0
            ? changelog?.filter(el => filters.includes(el?.device))?.map((change, index) => {
                
                  return (
                    <Accordion.Item eventKey={`${index}`} key={index}>
                      <Accordion.Header className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-start justify-content-start flex-column">
                          <div className="d-flex justify-content-center align-items-center titles-container">
                            <div
                              className={`me-3 font-size-16 user-select-none ${handleChangeType(
                                change?.type
                              )} font-gotham-medium`}
                            >
                              {handleUpdateType(change?.type)}
                            </div>
                            <div className="user-select-none font-size-16 device-container text-center font-gotham-medium">
                              {handleIconDevice(change?.device)}{" "}
                              {handleName(change?.device)}
                            </div>
                          </div>
  
                          <div className="my-3 font-size-24 user-select-none ms-4 font-gotham-medium font-size-24">
                            {change?.version}
                          </div>
                          <div className="user-select-none font-size-20 font-gotham-book gray-color font-size-20 text-uppercase">
                            {new Date(change?.date).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                          </div>
                        </div>
  
                        <AiOutlinePlus className="icon close-icon" />
                      </Accordion.Header>
                      <Accordion.Body className="d-flex flex-column ps-0 gray-color">
                        {change?.updates?.map((update, index) => {
                          return (
                            <div key={index}>
                              <div
                                className={`${handleTitleClasses(
                                  update.type
                                )} d-flex align-items-center font-size-16 user-select-none font-gotham-medium justify-content-center white-color`}
                              >
                                {update.type}
                              </div>
                              <div className="font-size-24 user-select-none font-gotham-book mt-2">
                                {update.title}
                              </div>
                              <div className="mb-2">
                                {Array.isArray(update.description) ? (
                                  update?.description?.map((descr, index) => {
                                    return <div key={index} className="font-size-18 font-gotham-book">{descr}</div>;
                                  })
                                ) : (
                                  <div className="font-size-18 font-gotham-book">{update.description}</div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
              })
            : <div className="font-size-24 font-gotham-book black-color text-center mt-5">No updates avaiable!</div>}
        </Accordion>
      </div>
    </section>
  );
};

export default ChangeLog;
